
#golfers {
  clear: both;
  text-align: center;
  font-family: Trebuchet MS, Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid purple;
  margin-top: 0.10em;
  position: relative;
}

#golfers tbody {
  width: auto;
  margin-left: auto;
  margin-right:auto;
}

#golfers tbody tr {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
#golfers tbody tr th {
  text-align: center;
  font-size: xx-small;
}

#golfers td {
  border: 1px solid green;
  font-size: xx-small;
  padding: 0px;
}

#golfers input {
  text-align: center;
  height: 1.4em;
  width: 1.13em;
  border: 1px solid green;
}

#golfers tr {
  background-color: #ddd;
  height: 1.02em;
  border-collapse: collapse;
}

#golfers th {
  border: 1px solid purple;
  height: 4px;
  padding: 1px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 0.4em;
  text-align: center;
  background-color: purple;
  color: white;
  border-collapse: collapse;
}

.scores-golfer {
  border:  3px solid purple;
  border-collapse:  collapse;
  width:  12.0em;
  margin-top:  9.8rem;
  margin-left:  auto;
  margin-right:  auto;
}

.scores-name {
  margin: 0;
  padding: 0;
  width: 24.0em;
  height: 1.58em;
  text-align: left;
  font-size: small;
  border-collapse: collapse;
  background-color: #d3f8d3;
}
/* 
.btnmenu-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  width: 16rem;
  border: 0px solid blue;
  margin-top: -38.5em;
  margin-left: auto;
  margin-right: auto;
  z-index: 999;
}

.btnmenu-container div {
  background-color: transparent;
  display: flex;
  margin: 0.25em;
  padding-top: 1px;
  text-align: center;
  font-size: x-small;
  color: white;
}

.btnmenu-container div a {
  color: white;
  cursor: pointer;
}
.btn-container {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: -9.3em;
  border: 1px solid red;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  margin-left: auto;
  margin-right: auto;
  width: 6.3em;
  padding: 2px 4px;
  color: white;
  font-size: 0.70em;
  border: none;
  background-color: green;
  border: 2px solid gold;
  border-radius: 10px;
  cursor: pointer;
}

.btn:hover {
  opacity: var(500)
} */