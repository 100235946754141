.helpdiv {
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid green;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  width: 19.0rem;
}

.helpcenter {
  display: flex;
  color: green;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.0rem;
  padding-right: 1.0rem;
  margin-bottom: 1.0rem;
}