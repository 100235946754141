.menu-container {
  float: left;
  padding-top: 0.55em;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 3.4em;
}

.menu-container div {
  background-color: green;
  margin: 0.25em;
  padding-top: 1px;
  text-align: center;
  font-size: x-small;
  color: white;
  border: 2px solid gold;
  /* border-radius: 15px; */
}

.menu-container div a {
  color: white;
  cursor: pointer;
}

.totals-container div {
  background-color: green;
  margin: 1px;
  padding: 1px;
  text-align: center;
  font-size: x-small;
  color: white;
  width: 7.20em;
  border: 2px solid gold;
}

.totals-container div:hover {
  background-color: red;
}

.totals-container {
  margin-top: 8.7em;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(222, 204, 222);
  width: 19.30em;
}

.under-container {
  margin-top: 8.7em;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  background-color: purple;
  width: 19.80em;
}

.under-container div {
  background-color: green;
  margin: 1px;
  padding: 1px;
  text-align: center;
  font-size: x-small;
  color: white;
  /* width: 7.14em; */
  /* width:auto; */
  width: 7.20em;
  border: 2px solid gold;
  /* border-radius: 15px; */
}

.hole-container div a {
  color: white;
  cursor: pointer;

}

.hole-container {
  /* float: left; */
  /* margin-top: -1.0em; */
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  /* background-color: transparent; */
  background-color: purple;
  width: 19.80em;
}

.hole-container div {
  background-color: green;
  margin: 1px;
  padding: 1px;
  text-align: center;
  font-size: x-small;
  color: white;
  /* width: 7.14em; */
  width: 7.20em;
  border: 2px solid gold;
  /* border-radius: 15px; */
}

.hole-container div a {
  color: white;
  cursor: pointer;
}

/* .course-container {
  float:left;
  position:relative;
  padding-top:1.4em;
  height:auto;
  width:auto;
  border: 1px solid red;
  margin-left: auto;
  margin-right: auto;
} */
/* blink css for test */
:root {
  --blink-time: 3s;
}

.centerfooter {
  /* margin-top: 0.15rem; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color:white;
  padding-top: 0.30rem;
  width: 20.32rem;
  height: 1.37rem;
  background-color: purple;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.blink {
  animation: var(--blink-time) blinker linear infinite;
  -webkit-animation: var(--blink-time) blinker linear infinite;
  -moz-animation: var(--blink-time) blinker linear infinite;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: -29.3em;
  border: 0px solid red;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  /* margin-left: 1.6em; */
  margin-left: auto;
  margin-right: auto;
  width: 5.0em;
  padding: 2px 4px;
  color: white;
  font-size: 0.70em;
  border: none;
  background-color: green;
  border: 2px solid gold;
  border-radius: 10px;
  cursor: pointer;
}

.btn:hover {
  /* background-color: */
  opacity: var(500)
}

.gray_clr {
  color: lightgray;
  font-size: 14px;
}

@-moz-keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* End blink css test */