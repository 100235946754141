#frontscores {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 0px solid #ddd;
  width: 17.5rem;
  padding: 0.0rem 1.35rem;
  margin-top: 0.10rem;
  margin-bottom: 0.10rem;
  position: relative;
}

#frontscores td {
  border: 0px solid green;
  font-size: xx-small;
  padding: 0px;
}

#frontscores input {
  text-align: center;
  height:1.4em;
  width:1.23em;
  border: 1px double green;
}

#frontscores tr {
  background-color: #ddd;
  height: 1.02em;
  border-collapse: collapse;
}
#frontscores th {
  border: 1px solid purple;
  /* border: 1px solid white; */
  height: 4px;
  padding: 1px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size:8px;
  text-align: center;
  background-color: purple;
  color: white;
  border-collapse: collapse;
}
#fontpars th  {
  font-size:0.8em;
}
.rndborder {
  border:1px double green;
  border-style: double;
  border-radius: 50px;
}
.sqrborder {
  border-radius: 0px;
}