#players {
  clear: both;
  text-align: center;
  font-family: Trebuchet MS, Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid purple;
  margin-top: 0.0em;
  width: 18.0rem;
  margin-bottom: 0.15rem;
  /* position: relative; */
}

#players tbody {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

#players tbody tr {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#players tbody tr th {
  text-align: center;
  font-size: xx-small;
}

#players td {
  border: 1px solid green;
  font-size: xx-small;
  padding: 0px;
}

#players input {
  text-align: center;
  height: 1.4em;
  width: 1.13em;
  border: 1px solid green;
}

#players tr {
  background-color: #ddd;
  height: 1.02em;
  border-collapse: collapse;
}

#players th {
  border: 1px solid purple;
  height: 4px;
  padding: 1px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 0.4em;
  text-align: center;
  background-color: purple;
  color: white;
  border-collapse: collapse;
}