.heading {
  background-image: url('./25-300Clubhouse.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 1.0rem;
  border-top-right-radius: 1.0rem;
  margin: 1.0rem auto;
  margin-top: 0.0rem;
  margin-bottom: 0.1rem; 
  padding: 1.5rem;
  width: 17.5rem;
  /* max-width: 90%; */
  text-align: center;
  font-size: 35px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: gold;
  -webkit-text-stroke: 1px green;
  font-weight: bold;
  position: inherit;
}