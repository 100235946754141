.course-labels {
  font-size: small;
  font-weight: bold;
  color:purple;
  margin-left: 0px;
}
#course input {
  color: blue;
  height: 0.90em;
  padding: 0.125em;
  /* margin: 0.0625em; */
  width:100px;
  text-align: left;
  font-size: small;
  margin-left: auto;
  margin-right: 1.5em;
}
::placeholder{
  color: green;
  opacity: .4;
  font-size: smaller;
}
.style select {
  width: 200px;
  padding: 0px;
  margin: 0%;
  height:1.5em;
  border: 2px solid purple;
}

.style input {
  width: 5.0rem;
  padding: 0px;
  height: 2px;
  border-width: 1px;
}

.cbtn {
  width:5.5em;
}

.container {
  display:table;
}

.column {
  display:table-cell;
  width: auto;
  border: 0px solid blue;
}
.colume-input{
  width: 9.0rem;
}
.row {
  display:table-row;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* border: 3px solid purple; */
}
/* displaytable css code */

#myTable {
  width: 100%;
  display:table;
  border: 1px solid purple;
}
#tblCaption {
  display:table-caption;
  text-align: center;
  font-size: medium;
  font-weight: bold;
}#tblHeader  {
  display:table-header-group;
  background-color:lightgray;
  font-weight: bold;
  font-size: medium;
}
.tblHeaderCell {
  display: table-cell;
  padding: 10px;
  text-align: justify;
  border-bottom: 1px solid black;
}
#tblBody {
  display:table-row-group;
}
#resp-table {
  width: 100%;
  display: table;
}

#resp-table-caption {
  display: table-caption;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

#resp-table-header {
  /* display: table-header-group; */
  display: table-row;
  background-color: lightgray;
  font-weight: bold;
  font-size: 25px;
}

#resp-table-body {
  display: table-row-group;
}

#resp-table-footer {
  display: table-footer-group;
  background-color: gray;
  font-weight: bold;
  font-size: 25px;
  color: rgba(255, 255, 255, 0.45);
}

.table-header-cell {
  display: table-cell;
  padding: 5px;
  text-align: justify;
  border-bottom: 1px solid black;
}

.resp-table-row {
  display: table-row;
}

.table-body-cell {
  display: table-cell;
}

.table-footer-cell {
  display: table-cell;
  padding: 10px;
  text-align: justify;
  border-bottom: 1px solid black;
}

.course_center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  border: 3px solid purple;
  /* border-radius: 10px; */
  /* width: 18.0em; */
  width: 17.5rem;
  padding: 0.0rem 1.35rem;
  margin-top: 0.0rem;
  /* margin-bottom: 2.3em; */
  margin-bottom: 0.1rem;
}

.course-container {
  float: left;
  position: relative;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  height: auto;
  width: auto;
  border: 0px solid red;
  margin-left: auto;
  margin-right: auto;
}