.andrew a {
/* margin: 0; */
/* padding: 0; */
/* padding-left: 1rem; */
/* padding-right: 1rem; */
/* margin-top: -20px; */
margin-left: auto;
margin-right: auto;
border: 0px solid purple;
/* height: auto; */
}
.allOfAd {
  padding-top:0px;
  border: 3px solid green;
  border-radius: 15px;
  width:90%;
  margin-left: auto;
  margin-right: auto;
}
.andrewlogo img {
  /* height:auto; */
  /* width: auto; */
  /* padding: 0; */
  /* border-radius: 20px; */
    margin: 20px;
/* border: 0px solid purple; */
}
.andrewmenu img {
  /* height:auto; */
  /* width: auto; */
  /* padding: 0; */
  margin-top: 0px;
  width: 100%;
  border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  /* border: 0px solid purple; */
}

.turnspecial {
  margin-top: -4px;
  margin-left: auto;
  margin-right: auto;
  display:flex;
  font-size:small;
  width: fit-content;
  font-weight: bolder;
  max-width: 100%;
  padding:  5px 10px 5px 10px;
  border-radius: 10px;
  background-color: darkgreen;
  color:gold;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
}
.phonenow {
  margin-top: -6px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  margin-bottom: 2px;
  padding: 7px;
  border-radius: 10px;
  width: fit-content;
    font-weight: bolder;
    /* background-color: lightgray; */
    background-color: darkgreen;
    color: gold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: italic;
}
.turnitem {
    margin-left: auto;
    margin-right: auto;
    padding-top: 1px;
    margin-top: 5px;
    margin-bottom: 0px;
    width: fit-content;
    font-size: small;
    font-weight: bolder;
    background-color: lightgray;
    color: black;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: italic;
}
.phonenumber {
    margin-left: auto;
      margin-right: auto;
      padding-bottom: 10px;
      /* border-bottom: 2px solid black; */
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 15px;
      margin-top: 5px;
      margin-bottom: 0px;
      width: fit-content;
      font-size: small;
      font-weight: bolder;
      background-color: lightgray;
      color: black;
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
      font-style: italic;
}
