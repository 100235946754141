/*
	styles.css
	Initial code generated by thesitewizard.com's Layout Wizard 3.0.1
*/
body {
  font-family: Arial, Helvetica, sans-serif;
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}

input,
select,
button,
textarea {
  max-width: 100%;
}

meter,
progress,
output {
  max-width: 100%;
}

div#tswcontent {
  width: 98%;
  /* height: 64.7em; */
  max-height: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #000;
  background-color: #fef6bb;
}

div#tswcontproper {
  padding-top: 2px;
  padding-bottom: 10px;
  padding-left: 2px;
  padding-right: 2px;
}

div#tswcontainer {
  color: inherit;
  background-color: inherit;
}
/*
	tswtabs.css		1.2.4
	Please use the CSS Menu Button Wizard at
		https://www.thesitewizard.com/wizards/css-menu-buttons.shtml
	to generate your own customized menu buttons.
*/

#tswcsstabs ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
}

#tswcsstabs ul li {
  margin: 0;
  padding: 2px;
  display: inline;
  text-align: center;
  float: left;
  /* width:100px; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: xx-small;
}
#tswcsstabs ul li button {
  margin: 0;
  padding: 2px;
  display: inline;
  text-align: center;
  float: left;
  /* width:100px; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: xx-small;
}

#tswcsstabs li button {
  color: #ffffff;
  background-color: #008000;
  /* border: 1px outset #000000; */
  border: 2px outset gold;
  border-radius: 15px;
  padding: 4px 5px;
  text-decoration: none;
  font-weight: bold;
  display: inline;
}

#tswcsstabs li button:hover {
  color: #000000;
  background-color: #00ff00;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}