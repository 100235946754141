.btnmenu-container {
  text-align: center;
  /* border: 2px solid purple; */
  width: 20.0rem;
  margin-left: auto;
  margin-right: auto;
}

.button-line {
  display: "flex";
  flex-direction: "row";
  height: 1.0rem;
  width: 21.0rem;
  margin-left: "auto";
  margin-right: "auto";
  margin-left: -8px;
  margin-bottom: 1.0rem;
  /* border: 1px solid blue; */
}

.btn {
  float: left;
  width: 3.02rem;
  padding: 0px 2px;
  color: white;
  text-align: center;
  font-size: 0.65rem;
  font-weight: 900;
  height: 1.6rem;
  background-color: green;
  border: 3px solid gold;
  border-radius: 10px;
  cursor: pointer;
  margin: 3px;
  margin-bottom: 0.2rem;
}

.btn:hover {
  background-color: lawngreen;
  opacity: .8;
}